<app-search></app-search>

<div class="box">
   <div *ngIf="block">
      <div class="grid-hash">
         <div>
            <span class="grid-hash-left"><i class="fas fa-hashtag"></i></span>
            <span class="grid-hash-middle breakable">{{block.blockHash}}</span>
            <span class="grid-hash-right">
               <div class="grid-hash-navigation">
                  <div *ngIf="block.blockIndex > 1">
                     <a [routerLink]="['../', block.blockIndex - 1]">
                        <i class="fas fa-cube"></i><br>
                        <span class="grid-hash-block-index">{{block.blockIndex - 1}}</span>
                     </a>
                  </div>
                  <div class="muted">
                     <i class="fas fa-cube"></i><br>
                     <span class="grid-hash-block-index">{{block.blockIndex}}</span>
                  </div>
                  <div *ngIf="block.blockIndex !== lastBlockHeight">
                     <a [routerLink]="['../', block.blockIndex + 1]">
                        <i class="fas fa-cube"></i><br>
                        <span class="grid-hash-block-index">{{block.blockIndex + 1}}</span>
                     </a>
                  </div>
                  <div *ngIf="block.blockIndex === lastBlockHeight">
                        <i class="fas fa-hammer"></i><br>
                        <span class="grid-hash-block-index">...</span>
                  </div>
               </div>
            </span>
         </div>
      </div>
   </div>
</div>

<div class="box">

   <a class="link block-details-toggle" (click)="toggleDetails()"><span *ngIf="!detailsVisible">View</span><span
         *ngIf="detailsVisible">Hide</span>
      details</a>

   <h3><i class="fas fa-cube"></i>&nbsp;&nbsp;Block Details</h3>

   <app-progress class="centered" *ngIf="!block"></app-progress>
   <app-error class="centered" [error]="error"></app-error>

   <div class="grid-label-value" *ngIf="block">
      <div>
         <span>Height</span>
         <span>{{block.blockIndex}}</span>
      </div>
      <div>
         <span>Age</span>
         <span>{{block.blockTime | ago}}</span>
      </div>
      <div>
         <span>Date</span>
         <span>{{block.blockTime | timestamp}}</span>
      </div>
      <div>
         <span>Confirmations</span>
         <span>{{block.confirmations}}</span>
      </div>
      <div>
         <span>Size</span>
         <span>{{block.blockSize}} bytes</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Unit Timestamp</span>
         <span>{{block.blockTime}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Difficulty</span>
         <span class="breakable">{{block.difficulty}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Bits</span>
         <span>{{block.bits}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Nonce</span>
         <span>{{block.nonce}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Merkle Root</span>
         <span class="breakable">{{block.merkleroot}}</span>
      </div>

      <div *ngIf="detailsVisible && block.posFlags !== 'proof-of-stake'">
         <span>chainWork</span>
         <span class="breakable">{{block.chainWork}}</span>
      </div>

      <div *ngIf="detailsVisible && block.posFlags === 'proof-of-stake'">
         <span>Proof-of-Stake BlockSignature</span>
         <span class="breakable">{{block.posBlockSignature}}</span>
      </div>
      <div *ngIf="detailsVisible && block.posFlags === 'proof-of-stake'">
         <span>Proof-of-Stake BlockTrust</span>
         <span class="breakable">{{block.posBlockTrust}}</span>
      </div>
      <div *ngIf="detailsVisible && block.posFlags === 'proof-of-stake'">
         <span>Proof-of-Stake ChainTrust</span>
         <span class="breakable">{{block.posChainTrust}}</span>
      </div>
      <div *ngIf="detailsVisible && block.posFlags === 'proof-of-stake'">
         <span>Proof-of-Stake HashProof</span>
         <span class="breakable">{{block.posHashProof}}</span>
      </div>
      <div *ngIf="detailsVisible && block.posFlags === 'proof-of-stake'">
         <span>Proof-of-Stake Modifierv2</span>
         <span class="breakable">{{block.posModifierv2}}</span>
      </div>

   </div>

</div>


<div class="box">
   <h3>Transactions ({{total}})</h3>

   <app-progress class="centered" *ngIf="!transactions">Loading transactions...</app-progress>

   <div *ngIf="transactions">
      <div class="grid-header">
         <span>Transaction ID</span>
      </div>

      <div class="scrollable" appDetectScroll (onScroll)="onScroll($event)" [bottomOffset]="400" [topOffset]="400">
         <div class="grid grid-odd-line" *ngFor="let item of transactions">
            <span><a class="nonbreakable" [routerLink]="['../../transaction', item.transactionHash]">{{item.transactionHash}}</a></span>
         </div>
         <br>
         <app-progress class="centered" *ngIf="loading">Loading more transactions...</app-progress>
      </div>
   </div>

</div>
